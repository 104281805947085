import {RouterDirection} from '@ionic/core';
import {ToastService} from '../services/toast.service';
import {AuthService} from '../services/auth.service';
import {FixedEncodeURIComponent} from './string-utils';
import {AccountService} from "../services/account.service";
import {StorageService} from "../services/storage.service";
import {i18n} from "../services/i18n.service";

export const GetDomain = () => {
  return location.protocol+'//'+location.hostname+(location.port ? ':'+location.port: '');
}

export const RouterNavigate = async (path, direction: RouterDirection = 'forward') => {
  const ionRouterElement: HTMLIonRouterElement = document.querySelector('ion-router');
  if(ionRouterElement) {
    return ionRouterElement.push(path, direction)
  }else{
    location.href=path;
  }
};


export const IsLoggedInGuardWithUserCheck = () => {
  const isLoggedIn = AccountService.isLoggedIn(AccountService.getAccount()?._id)
  if (isLoggedIn && AuthService.isAdmin()) {
    return true;
  } else {
    StorageService.set('budget', true);
    return {redirect: '/not-logged-in'}; // If a user is not logged in, they will be redirected to the /login page
  }
}

export const  OpenUrl = (event) => {
  event.preventDefault();
  event.stopPropagation();
  const href = event.currentTarget.querySelector('a').getAttribute("href");
  RouterNavigate(href,'forward').then();
}

export const RouterBack = () => {
  const ionRouterElement: HTMLIonRouterElement = document.querySelector('ion-router');
  if(ionRouterElement) {
    return ionRouterElement.back();
  }else{
    history.back();
  }
};

export const RouterCanNavGoBackThenGo = async () => {
  const nav: HTMLIonNavElement = document.querySelector('ion-nav');
  if (nav && await nav.canGoBack()) {
    return nav.pop({skipIfBusy: true});
  }
  return RouterNavigate("/", 'back').then();
};

export const RouterOpenPayment = (event) => {
  event.preventDefault();
  const successUrl = location.href.split('?')[0];
  RouterNavigate('/payment/'+btoa(FixedEncodeURIComponent(successUrl))).then();
};

export const RouterGetUriParam = (name) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  if(urlParams.has(name)) {
    return urlParams.get(name);
  }else{
    return null;
  }
};

export const RouterErrorHandling = (error, text = {
  connectionError:{
    message: i18n('You have been logged out because your session is no longer valid!').t('de','Du wurdest ausgeloggt, da Deine Sitzung nicht mehr gültig ist!').get()
  },
  defaultError:{
    title : i18n('Problem').t('de','Problem').get(),
    message: i18n('An error occurred. Please try again later.').t('de','Ein Fehler ist aufgetreten. Bitte versuche es später erneut.').get()
  }
}, handler = () => {
}) => {

  switch (error?.systemCode) {
    case "inputdatanotvalid":
    case "useralreadyexists":
      break
    case "authenticationheaderisinvalid":
    case "authenticationhasfailed":
    case "authenticationrequired":
      ToastService.presentToast(
        text.connectionError.message,
        false, 5000).then(
        () => {
          AuthService.logout();
          location.href = '/';
        });
      break;
    default:
      ToastService.presentToastWithButtons(
        text.defaultError.title,
        text.defaultError.message, [
        {
          text: "refresh",
          handler
        }], null, 'bottom', 'secondary').then()
  }


};
